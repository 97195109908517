import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { getJson } from '@mobiscroll/react';

function Admin() { 
    
    const Idt_Usr = localStorage.getItem('token');
    
    const [Idt_Crp, setIdt_Crp] = useState(0);
    const [Nme_Crp, setNme_Crp] = useState('');
    const [correspondant, setCorrespondant] = useState([]);
    const [showAddCrp, setShowAddCrp] = useState(false);
    const [showEditCrp, setShowEditCrp] = useState(false);
    const [showCrp, setShowCrp] = useState(false);
    
    const [Idt_Opt, setIdt_Opt] = useState(0);
    const [Nme_Opt, setNme_Opt] = useState('');
    const [operation, setOperation] = useState([]);
    const [showAddOpt, setShowAddOpt] = useState(false);
    const [showEditOpt, setShowEditOpt] = useState(false);
    const [showOpt, setShowOpt] = useState(false);
    
    const [Idt_Int, setIdt_Int] = useState(0);
    const [Nme_Int, setNme_Int] = useState('');
    const [intervention, setIntervention] = useState([]);
    const [showAddInt, setShowAddInt] = useState(false);
    const [showEditInt, setShowEditInt] = useState(false);
    const [showInt, setShowInt] = useState(false);

    const getCorrespondant = () => {
        getJson('/api/ajax.php?ajax=getCorrespondant', (correspondant) => {
            setCorrespondant(correspondant);
        });
    }
    const getOperation = () => {
        getJson('/api/ajax.php?ajax=getOperation', (operation) => {
            setOperation(operation);
        });
    }
    const getIntervention = () => {
        getJson('/api/ajax.php?ajax=getIntervention', (intervention) => {
            setIntervention(intervention);
        });
    }

    useEffect(() => { getCorrespondant();}, []);
    useEffect(() => { getOperation(); }, []);
    useEffect(() => { getIntervention(); }, []);

    const crpList = (e) => {
        setNme_Crp($(e.target).attr('value'));
        setIdt_Crp($(e.target).attr('name'));
        setShowAddCrp(false);
        setShowEditCrp(true);
    }
    const crpEditChange = (e) => {
        setNme_Crp(e.target.value);
        setShowEditCrp(true);
    }
    const crpAddChange = (e) => {
        setNme_Crp(e.target.value);
        setShowEditCrp(false);
    }
    const showCrpButton = () => {
        setNme_Crp('');
        setShowAddCrp(true);
        setShowEditCrp(false);
    }

    const editscrollList = () => {
        $('li').css("color", "unset");
        $('.adminNameList').scrollTop($('.adminNameList').scrollTop() +$('li').position().top);
    
        setTimeout(() => {
            if($(`.crp_${Idt_Crp}`).length > 0) {
                 $('.adminNameList').animate({
                    scrollTop: $(`#${Idt_Crp}`).offset().top + 10,
                }, 500);
                $(`#${Idt_Crp}`).css("color", "#4fd3f8");
            }
            if($(`.opt_${Idt_Opt}`).length > 0) {
                $('.adminNameList').animate({
                   scrollTop: $(`#${Idt_Opt}`).offset().top + 10,
               }, 500);
               $(`#${Idt_Opt}`).css("color", "#4fd3f8");
            }
            if($(`.int_${Idt_Int}`).length > 0) {
                $('.adminNameList').animate({
                    scrollTop: $(`#${Idt_Int}`).offset().top + 10,
                }, 500);
                $(`#${Idt_Int}`).css("color", "#4fd3f8");
            }
        }, 2000);
    }

    const addscrollList = () => {
        let max = 0;
        $('li').css("color", "unset");
        $('.adminNameList').scrollTop($('.adminNameList').scrollTop() +$('li').position().top);
        
        setTimeout(() => {
            $('.maxId').each(function() {
                max = Math.max(this.id, max);
            });
            $('.adminNameList').animate({
                scrollTop: $(`#${max}`).offset().top + 10
            }, 500);
            $(`#${max}`).css("color", "#4fd3f8");
        }, 1500);
    }
      
    const editCrp = () => {
        editscrollList();
        if(Nme_Crp != '') {
            axios.post(`/api/function.php?ajax=editAdminCorrespondant&Idt_Usr=${Idt_Usr}&Nme_Crp=${Nme_Crp}&Idt_Crp=${Idt_Crp}`).then((res) => {
                console.log(res.data)
            });
        }
        setTimeout(() => {
            getCorrespondant();
        }, 1000);
    }

    const addCrp = () => {
        addscrollList();
        setNme_Crp('');
        if(Nme_Crp != '') {
            axios.post(`/api/function.php?ajax=addAdminCorrespondant&Idt_Usr=${Idt_Usr}&Nme_Crp=${Nme_Crp}&Idt_Crp=${Idt_Crp}`).then((res) => {
               console.log(res.data)
            });
        }
        setTimeout(() => {
            getCorrespondant();
        }, 1000);
    }

    const optList = (e) => {
        setNme_Opt($(e.target).attr('value'));
        setIdt_Opt($(e.target).attr('name'));
        setShowAddOpt(false);
        setShowEditOpt(true);
    }
    const optEditChange = (e) => {
        setNme_Opt(e.target.value);
        setShowEditOpt(true);
    }
    const optAddChange = (e) => {
        setNme_Opt(e.target.value);
        setShowEditOpt(false);
    }
    const showOptButton = () => {
        setNme_Opt('');
        setShowAddOpt(true);
        setShowEditOpt(false);
    }
    const editOpt = () => {
        editscrollList();
        if(Nme_Opt != '') {
            axios.post(`/api/function.php?ajax=editAdminOperation&Idt_Usr=${Idt_Usr}&Nme_Opt=${Nme_Opt}&Idt_Opt=${Idt_Opt}`).then((res) => {
                console.log(res.data)
            });
        }
        setTimeout(() => {
            getOperation();
        }, 1000);
    }
    const addOpt = () => {
        addscrollList();
        setNme_Opt('');
        if(Nme_Opt != '') {
            axios.post(`/api/function.php?ajax=addAdminOperation&Idt_Usr=${Idt_Usr}&Nme_Opt=${Nme_Opt}&Idt_Opt=${Idt_Opt}`).then((res) => {
                console.log(res.data)
            });
        }
        setTimeout(() => {
            getOperation();
        }, 1000);
    }

    const intList = (e) => {
        setNme_Int($(e.target).attr('value'));
        setIdt_Int($(e.target).attr('name'));
        setShowAddInt(false);
        setShowEditInt(true);
    }
    const intEditChange = (e) => {
        setNme_Int(e.target.value);
        setShowEditInt(true);
    }
    const intAddChange = (e) => {
        setNme_Int(e.target.value);
        setShowEditInt(false);
    }
    const showIntButton = () => {
        setNme_Int('');
        setShowAddInt(true);
        setShowEditInt(false);
    }
    const editInt = () => {
        editscrollList();
        if(Nme_Int != '') {
            axios.post(`/api/function.php?ajax=editAdminIntervention&Idt_Usr=${Idt_Usr}&Nme_Int=${Nme_Int}&Idt_Int=${Idt_Int}`).then((res) => {
                console.log(res.data)
            });
        }
        setTimeout(() => {
            getIntervention();
        }, 1000);
    }
    const addInt = () => {
        addscrollList();
        setNme_Int('');
        if(Nme_Int != '') {
            axios.post(`/api/function.php?ajax=addAdminIntervention&Idt_Usr=${Idt_Usr}&Nme_Int=${Nme_Int}&Idt_Int=${Idt_Int}`).then((res) => {
                console.log(res.data)
            });
        }
        setTimeout(() => {
            getIntervention();
        }, 1000);
    }

    const showCrpBloc = () => {
        setShowCrp(true);
        setShowOpt(false);
        setShowAddOpt(false);
        setShowEditOpt(false);
        setShowInt(false);
        setShowAddInt(false);
        setShowEditInt(false);
    }
    const showOptBloc = () => {
        setShowOpt(true);
        setShowCrp(false);
        setShowAddCrp(false);
        setShowEditCrp(false);
        setShowInt(false);
        setShowAddInt(false);
        setShowEditInt(false);
    }
    const showIntBloc = () => {
        setShowInt(true);
        setShowCrp(false);
        setShowAddCrp(false);
        setShowEditCrp(false);
        setShowOpt(false);
        setShowAddOpt(false);
        setShowEditOpt(false);
    }

    return (
        <div className='row'>
          <div className='col-lg-2 col-md-3 col-sm-12 adminMenu'>
            <p onClick={showCrpBloc}>Correspondants</p>
            <p onClick={showOptBloc}>Opérations</p>
            <p onClick={showIntBloc}>Interventions</p>
          </div>

          {/* CORRESPONDANT */} 
          {showCrp ?
            <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className="d-flex my-2">
                    <p className="m-0 px-1 col-lg-9 col-md-8"> <strong> Correspondants: </strong></p>
                    <button className="mx-1 col-lg-3 col-md-4 btn btn-sm btn-organizer" onClick={showCrpButton}>Nouveau</button>
                </div>
                <div className="mbsc-form-group">
                    <ul onClick={crpList} className="adminNameList">
                        {correspondant.map((crp, index) => ( 
                            <li key={index} className={`maxId crp_${crp.Idt_Crp}`} name={crp.Idt_Crp} id={crp.Idt_Crp} value={crp.Nme_Crp}> {crp.Nme_Crp} </li>
                        ))}
                    </ul>
                    {showEditCrp ?
                    <div className="d-flex">
                        <input className="col-7" type="text" name="Nme_Crp" value={Nme_Crp} onChange={crpEditChange} />
                        <button className="col-2 mx-1 btn btn-sm btn-organizer" onClick={editCrp}>Valider</button>
                    </div>
                    : null}
                    {showAddCrp ?
                    <div className="d-flex">
                        <input className="col-9" type="text" name="Nme_Crp" value={Nme_Crp} onChange={crpAddChange} />
                        <button className="col-2 mx-1 btn btn-sm btn-organizer" onClick={addCrp}>Valider</button>
                    </div>
                    : null}
                </div>
            </div>
          :null}

          {/* OPERATION */} 
          {showOpt ?
            <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className="d-flex my-2">
                    <p className="m-0 px-1 col-lg-9 col-md-8"> <strong> Opérations: </strong></p>
                    <button className="mx-1 col-lg-3 col-md-4 btn btn-sm btn-organizer" onClick={showOptButton}>Nouveau</button>
                </div>
                <div className="mbsc-form-group">
                    <ul onClick={optList} className="adminNameList">
                        {operation.map((opt, index) => ( 
                            <li key={index} className={`maxId opt_${opt.Idt_Opt}`} name={opt.Idt_Opt} id={opt.Idt_Opt} value={opt.Nme_Opt}> {opt.Nme_Opt} </li>
                        ))}
                    </ul>
                    {showEditOpt ?
                    <div className="d-flex">
                        <input className="col-7" type="text" name="Nme_Opt" value={Nme_Opt} onChange={optEditChange} />
                        <button className="col-2 mx-1 btn btn-sm btn-organizer" onClick={editOpt}>Valider</button>
                    </div>
                    : null}
                    {showAddOpt ?
                    <div className="d-flex">
                        <input className="col-9" type="text" name="Nme_Opt" value={Nme_Opt} onChange={optAddChange} />
                        <button className="col-2 mx-1 btn btn-sm btn-organizer" onClick={addOpt}>Valider</button>
                    </div>
                    : null}
                </div>
            </div>
          :null}

          {/* INTERVENTION */} 
          {showInt ? 
            <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className="d-flex my-2">
                    <p className="m-0 px-1 col-lg-9 col-md-8"> <strong> Interventions: </strong></p>
                    <button className="mx-1 col-lg-3 col-md-4 btn btn-sm btn-organizer" onClick={showIntButton}>Nouveau</button>
                </div>
                <div className="mbsc-form-group">
                    <ul onClick={intList} className="adminNameList">
                        {intervention.map((int, index) => ( 
                            <li key={index} className={`maxId int_${int.Idt_Int}`} name={int.Idt_Int} id={int.Idt_Int} value={int.Nme_Int}> {int.Nme_Int} </li>
                        ))}
                    </ul>
                    {showEditInt ?
                    <div className="d-flex">
                        <input className="col-7" type="text" name="Nme_Int" value={Nme_Int} onChange={intEditChange} />
                        <button className="col-2 mx-1 btn btn-sm btn-organizer" onClick={editInt}>Valider</button>
                    </div>
                    : null}
                    {showAddInt ?
                    <div className="d-flex">
                        <input className="col-9" type="text" name="Nme_Int" value={Nme_Int} onChange={intAddChange} />
                        <button className="col-2 mx-1 btn btn-sm btn-organizer" onClick={addInt}>Valider</button>
                    </div>
                    : null}
                </div>
            </div>
          :null}
        </div>
    )
}

export default Admin;