import React from 'react';
import Logo from './assets/img/logo.png';
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom'; 
import { Navbar, Nav, Button } from 'react-bootstrap';

import Login from './components/Login';
import Agenda from './components/Agenda';
import Patient from './components/Patient';
import Admin from './components/Admin';
import User from './components/User';
import Ccam from './components/Ccam';

import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/Custom.css';

function App() {
    const token = localStorage.getItem('token');
    const level = localStorage.getItem('level');

    let hours = 1;
    let expired = localStorage.getItem('expire');
    if (expired && (new Date().getTime() - expired > hours * 120 * 120 * 1000)) {
        localStorage.clear();
        window.location.reload(true);
    }
    
    const handleLogout = () => {
        setTimeout(() => {
            localStorage.clear();
            window.location.reload(true);
        }, 50);
    };
    
    return (
        <div className="container-fluid p-0">
          <Router>
              
            {token !== null ?
                <Navbar expand="lg" className="bgOrganizer p-2">
                    <Navbar.Brand href="/">
                        <img src={Logo} alt="Web Organizer Logo" height="25" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Link to={'/agenda'} className="nav-link" onClick={() => setTimeout(() => {window.location.reload()}, 50)}> Agenda </Link>
                            {level > 12 ? <Link to={'/patient'} className="nav-link"> Patients </Link> : null}
                            {level > 14 ?<Link to={'/admin'} className="nav-link" onClick={() => setTimeout(() => {window.location.reload()}, 50)}> Administration </Link> : null}
                            <Link to={'/ccam'} className="nav-link" onClick={() => setTimeout(() => {window.location.reload()}, 50)}> CCAM </Link>
                            {level > 14 ? <Link to={'/user'} className="nav-link" onClick={() => setTimeout(() => {window.location.reload()}, 50)}> Utilisateurs </Link> : null}
                            {/* <Link to={'/websurvey'} target="_blank" className="nav-link"> WebSurvey </Link> */}
                        </Nav>
                        <Link to={'/'} className="nav-link"> 
                            <Button className="btn-sm btn-organizer" onClick={handleLogout}> Déconnexion </Button>
                        </Link>
                    </Navbar.Collapse>
                </Navbar>
            : null }

            <Switch>
                <Route exact path="/" component={token===null ? Login : Agenda} />
                <Route path="/agenda" component={token===null ? Login : Agenda} />
                {level > 12 ? <Route path="/patient" component={token===null ? Login : Patient} /> :null}
                {level > 14 ? <Route path="/admin" component={token===null ? Login : Admin} /> :null}
                <Route path="/ccam" component={token===null ? Login : Ccam} />
                {level > 14 ? <Route path="/user" component={token===null ? Login : User} /> :null}
                {/* <Route path="/websurvey" render={() => window.location = "https://www.websurvey.fr"} /> */}
            </Switch>

          </Router>
        </div>
    )
}

export default App;