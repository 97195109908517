import React, { useState, useEffect, useCallback } from 'react';
import { getJson } from '@mobiscroll/react';
import { FormControl, Form, FloatingLabel, Button, Table, Spinner } from 'react-bootstrap';
import { Pagination } from "@material-ui/core";
import { CSVLink } from "react-csv";
import usePagination from "./Pagination";
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';

function Patient() {

    const [dataTable, setDataTable] = useState([]);
    
    const [filteredData, setFilteredData] = useState(dataTable);
    const [search, setSearch] = useState('');
    const [searchPathology, setSearchPathology] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    
    let [page, setPage] = useState(1);
    const PER_PAGE = 200;
    
    // PAGINATION COUNT
    let [pagination, setPagination] = useState(1);

    const _DATA = usePagination(filteredData.length > 0 ? filteredData : dataTable, PER_PAGE);
  
    const handlePageChange = (e, p) => {
      setPage(p);
      _DATA.jump(p);
    };
    
    let limit = ((page-1)*200-1)+1;

    const [filtreoperation, setFiltreoperation] = useState([]);
    const [filtreintervention, setFiltreintervention] = useState([]);
    const [filtreannee, setFiltreannee] = useState([]);
    const [filtrenaissance, setFiltrenaissance] = useState([]);
    
    const [operationId, setOperationId] = useState('');
    const [interventionId, setInterventionId] = useState('');
    const [optYear, setOptYear] = useState('');
    const [birthYear, setBirthYear] = useState('');
    const [loader, setLoader] = useState(undefined);

    let [orderBy] = useState('');
    const [order, setOrder] = useState(false);
    const [name, setName] = useState('lastname');
    const columnName = [
        // {label: "Pra", key: "praticien"},
        {label: "Nom", key: "lastname"},
        {label: "Prénom", key: "firstname"},
        {label: "Devis", key: "devis"},
        {label: "Facture", key: "payment"},
        {label: "Paiement", key: "validPayment"},
        {label: "Date naissance", key: "birthYear"},
        {label: "Correspondant 1", key: "correspondant1"},
        {label: "Correspondant 2", key: "correspondant2"},
        {label: "Date opération", key: "optYear"},
        {label: "Type opération", key: "optType"},
        {label: "Intervention", key: "intType"},
        {label: "Ccam1", key: "ccam1"},
        {label: "Ccam2", key: "ccam2"},
        {label: "Remarques", key: "remark"},
        {label: "Suivi 6 sem", key: "suivi6Sem"},
        {label: "Suivi 3 mois", key: "suivi3Mois"},
        {label: "Suivi 6 mois", key: "suivi6Mois"},
        {label: "Suivi 1 an", key: "suivi1An"},
        {label: "Suivi 2 ans", key: "suivi2Ans"},
        {label: "Suivi 3 ans", key: "suivi3Ans"},
        {label: "Suivi 4 ans", key: "suivi4Ans"},
        {label: "Suivi 5 ans", key: "suivi5Ans"}
    ];

    // Export CSV
    const csvReport = {
        data: filteredData.length > 0 ? filteredData : dataTable,
        headers: columnName,
        filename: 'export.csv'
    };

    useEffect(() => {
        getJson('/api/ajax.php?ajax=getOperation', (filtreoperation) => {
            setFiltreoperation(filtreoperation);
        });
    }, []);
    
    useEffect(() => {
        getJson('/api/ajax.php?ajax=getIntervention', (filtreintervention) => {
            setFiltreintervention(filtreintervention);
        });
    }, []);
    
    useEffect(() => {
        getJson('/api/ajax.php?ajax=getOptYear', (filtreannee) => {
            setFiltreannee(filtreannee);
        });
    }, []);

    useEffect(() => {
        getJson('/api/ajax.php?ajax=getBirthYear', (filtrenaissance) => {
            setFiltrenaissance(filtrenaissance);
        });
    }, []);

    
    // Select Praticiens on Menu
    $(function() {
        $("#praticienPatient").appendTo(".navbar .navbar-nav");
        $(".navbar .navbar-nav #selectedPraticien").remove();
    });
    
    const [IdtPra_Cal, setIdtPra_Cal] = useState('');
    const pra_nme = localStorage.getItem("pra_nme").split(',');
    const pra_val = localStorage.getItem("pra_val").split(',');
    const accessPraticien = pra_nme.map(function(item, index) {
        return {text: pra_nme[index], value: Number(pra_val[index])};
    });

    const filterPra = (ev) => {
        setPage(1);
        setIdtPra_Cal(ev.target.value);
        axios.get(`/api/ajax.php?ajax=getPatientData&IdtPra_Cal=${IdtPra_Cal}&filtreoperation=${operationId}&filtreintervention=${interventionId}&filtreannee=${optYear}&filtrenaissance=${birthYear}&searchBy=${search}&searchByPat=${searchPathology}&orderColumn=${name}&orderBy=ASC&limit=${limit}`).then((res) => {
            setFilteredData(filteredData);
        });
    };

    const nameChange = (ev) => {
        setName(ev.target.name);
        setOrder(!order);
        axios.get(`/api/ajax.php?ajax=getPatientData&filtreoperation=${operationId}&filtreintervention=${interventionId}&filtreannee=${optYear}&filtrenaissance=${birthYear}&searchBy=${search}&searchByPat=${searchPathology}&orderColumn=${name}&orderBy=${orderBy}&limit=${limit}`).then((res) => {
            setDataTable(res.data);
        });
    }
    
    const operationChange = useCallback((ev) => {
        setPage(1);
        setOperationId(ev.target.value);
        setFilteredData(filteredData);
    }, [filteredData]);
    
    const interventionChange = useCallback((ev) => {
        setPage(1);
        setInterventionId(ev.target.value);
        setFilteredData(filteredData);
    }, [filteredData]);
    
    const operationYearChange = useCallback((ev) => {
        setPage(1);
        setOptYear(ev.target.value);
        setFilteredData(filteredData);
    }, [filteredData]);
    
    const birthYearChange = useCallback((ev) => {
        setPage(1);
        setBirthYear(ev.target.value);
        setFilteredData(filteredData);
    }, [filteredData]);

    const searchData = (e) => {
        setPage(1);
        setSearch(e.target.value);
        axios.get(`/api/ajax.php?ajax=getPatientData&filtreoperation=${operationId}&filtreintervention=${interventionId}&filtreannee=${optYear}&filtrenaissance=${birthYear}&searchBy=${e.target.value}&searchByPat=${searchPathology}&orderColumn=${name}&orderBy=${orderBy}&limit=${limit}`).then((res) => {
            setDataTable(res.data);
            res.data.length === 0 ? setErrorMsg("Aucune donnée trouvée !") : setErrorMsg('');
        });
    }

    const searchPathologyData = (e) => {
        setPage(1);
        setSearchPathology(e.target.value);
        axios.get(`/api/ajax.php?ajax=getPatientData&filtreoperation=${operationId}&filtreintervention=${interventionId}&filtreannee=${optYear}&filtrenaissance=${birthYear}&searchBy=${search}&searchByPat=${e.target.value}&orderColumn=${name}&orderBy=${orderBy}&limit=${limit}`).then((res) => {
            setDataTable(res.data);
            res.data.length === 0 ? setErrorMsg("Aucune donnée trouvée !") : setErrorMsg('');
        });
    }
    
    const filterFields = useCallback(() => {
        setPage(1);
        axios.get(`/api/ajax.php?ajax=getPatientData&IdtPra_Cal=${IdtPra_Cal}&filtreoperation=${operationId}&filtreintervention=${interventionId}&filtreannee=${optYear}&filtrenaissance=${birthYear}&searchBy=${search}&searchByPat=${searchPathology}&orderColumn=${name}&orderBy=${orderBy}&limit=${limit}`).then((res) => {
            setDataTable(res.data);
            res.data.length === 0 ? setErrorMsg("Aucune donnée trouvée !") : setErrorMsg('');
        });
        
    }, [IdtPra_Cal, operationId, interventionId, optYear, birthYear, search, searchPathology, name, order, orderBy, limit]);

    const resetFields = () => {
        window.location.reload(); 
    }

    useEffect(() => {
        setLoader(false);
        setTimeout(() => {
            order ?
                getJson(`/api/ajax.php?ajax=getPatientData&IdtPra_Cal=${IdtPra_Cal}&filtreoperation=${operationId}&filtreintervention=${interventionId}&filtreannee=${optYear}&filtrenaissance=${birthYear}&searchBy=${search}&searchByPat=${searchPathology}&orderColumn=${name}&orderBy=DESC&limit=${limit}`, (dataTable) => {
                    setDataTable(dataTable);
                    setLoader(true);
                    dataTable.length === 0 ? setErrorMsg("Aucune donnée trouvée !") : setErrorMsg('');
                })
            :
                getJson(`/api/ajax.php?ajax=getPatientData&IdtPra_Cal=${IdtPra_Cal}&filtreoperation=${operationId}&filtreintervention=${interventionId}&filtreannee=${optYear}&filtrenaissance=${birthYear}&searchBy=${search}&searchByPat=${searchPathology}&orderColumn=${name}&orderBy=ASC&limit=${limit}`, (dataTable) => {
                    setDataTable(dataTable);
                    setLoader(true);
                    dataTable.length === 0 ? setErrorMsg("Aucune donnée trouvée !") : setErrorMsg('');
                });
                
            getJson(`/api/pagination.php?filtreoperation=${operationId}&IdtPra_Cal=${IdtPra_Cal}&filtreintervention=${interventionId}&filtreannee=${optYear}&filtrenaissance=${birthYear}&searchBy=${search}&searchByPat=${searchPathology}&orderColumn=${name}&orderBy=${orderBy}`, (pagination) => {
                setPagination(Math.ceil(pagination/200));
            })
        }, 1000);
        
    }, [IdtPra_Cal, operationId, interventionId, optYear, birthYear, search, searchPathology, name, order, orderBy, limit]);


    return (
    <>

      <div className="mbsc-form-group bg-white" id="praticienPatient">
        <label>Patient de &nbsp;</label>
        <select value={IdtPra_Cal} onChange={filterPra}>
            <option value=''>Sélectionner un praticien</option>
            {accessPraticien.map((pra, index) => ( 
                <option key={index} value={pra.value}> {pra.text} </option>
            ))} 
        </select>
      </div>

      <Form className="container-fluid" id="patient-form">
             
        {/* Table */}
        <div className="row">
          <div className="col-md-2 col-sm-12">

            {/* Search Bar */}
            <FormControl
                type="search"
                placeholder="Rechercher par nom ou prénom du patient"
                className="mb-3 p-2 searchBar"
                aria-label="Search"
                onChange={searchData}
                value={search}
            />

            <FormControl
                type="search"
                placeholder="Rechercher par pathologie"
                className="mb-3 p-2 searchBar"
                aria-label="Search"
                onChange={searchPathologyData}
                value={searchPathology}
            />

            {/* Search Fields */}
            <FloatingLabel controlId="operationId" label="Par opération" value={operationId} onChange={operationChange}>
                <Form.Select>
                    <option value=''>Tout</option>
                    {filtreoperation.map((opt, index) => ( 
                        <option key={index} value={opt.Nme_Opt}> {opt.Nme_Opt} </option>
                    ))} 
                </Form.Select>
            </FloatingLabel>

            <FloatingLabel controlId="interventionId" label="Par intervention" value={interventionId} onChange={interventionChange}>
                <Form.Select>
                    <option value=''>Tout</option>
                    {filtreintervention.map((int, index) => ( 
                        <option key={index} value={int.Nme_Int}> {int.Nme_Int} </option>
                    ))}
                </Form.Select>
            </FloatingLabel>

            <FloatingLabel controlId="operationYear" label="Par année" value={optYear} onChange={operationYearChange}>
                <Form.Select>
                    <option value=''>Tout</option>
                    {filtreannee.map((optA, index) => ( 
                        <option key={index} value={optA.operationYear}> {optA.operationYear} </option>
                    ))} 
                </Form.Select>
            </FloatingLabel>

            <FloatingLabel controlId="birthYear" label="Par année de naissance" value={birthYear} onChange={birthYearChange}>
                <Form.Select>
                    <option value=''>Tout</option>
                    {filtrenaissance.map((birthA, index) => ( 
                        <option key={index} value={birthA.birthYear}> {birthA.birthYear} </option>
                    ))} 
                </Form.Select>
            </FloatingLabel>

            <Button type="button" className="btn btn-sm btn-organizer my-2 mx-1" onClick={filterFields}>Filtrer</Button>
            <Button type="reset" className="btn btn-sm btn-organizer my-2 mx-1" onClick={resetFields}>Réinitialiser</Button>
            <CSVLink {...csvReport} className="btn btn-sm btn-organizer my-2 mx-1">Export</CSVLink>
          </div>


          <div className="col-md-10 col-sm-12">
            
            {/* Loader */}
            {!loader ? 
                <div className="text-center mt-5">
                    <Spinner animation="grow" />
                </div>
            :

            // Error Message
            errorMsg ? <p className="alert alert-danger p-2 text-center">{errorMsg}</p> : 
            <>
            {/* Pagination */}
            <Pagination
                count={pagination}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handlePageChange}
                className="paginationBlock pb-3"
            />

            {/* Data */}
            <Table bordered hover responsive>
                <thead>
                  <tr className="text-center align-middle">
                    <th>#</th>
                    <th></th>
                    {columnName.map((name, index) => (
                        <th key={index} onClick={nameChange}>
                            <input className="table-header" name={name.key} value={name.label} style={{width: (name.label.length-1) + 'vh'}} readOnly />
                            <span className="d-none">{order ? orderBy='ASC' : orderBy='DESC'}</span>
                        </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {_DATA.currentData().map((data, index) => (
                  <tr key={data.id} className="align-middle">
                    <td>{200 * (page-1) + (index + 1)}</td>

                    {/* Link to Agenda */}
                    <td className='searchEvent'>
                        {data.optYear==="0000-00-00 00:00:00" ? null : 
                            <a href={`/?year=${moment(data.optYear).format('YYYY')}&month=${moment(data.optYear).format('MM')}&pra=${data.praticien}&lastname=${data.lastname}&firstname=${data.firstname}`}>
                                <i className="bi bi-calendar-check"></i>
                            </a>
                        }
                    </td>
                    
                    {/* <td>{data.praticien}</td> */}
                    <td>{data.lastname}</td>
                    <td>{data.firstname}</td>
                    <td>{data.devis}</td>
                    <td>{data.payment}</td>
                    <td>{data.validPayment}</td>
                    <td>{data.birthYear==="0000-00-00 00:00:00" ? null : moment(data.birthYear).format('DD/MM/YYYY')}</td>
                    <td>{data.correspondant1}</td>
                    <td>{data.correspondant2}</td>
                    <td>{data.optYear==="0000-00-00 00:00:00" ? null : moment(data.optYear).format('DD/MM/YYYY')}</td>
                    <td>{data.optType}</td>
                    <td>{data.intType}</td>
                    <td>{data.ccam1}</td>
                    <td>{data.ccam2}</td>
                    <td>{data.remark}</td>
                    <td>{data.suivi6Sem}</td>
                    <td>{data.suivi3Mois}</td>
                    <td>{data.suivi6Mois}</td>
                    <td>{data.suivi1An}</td>
                    <td>{data.suivi2Ans}</td>
                    <td>{data.suivi3Ans}</td>
                    <td>{data.suivi4Ans}</td>
                    <td>{data.suivi5Ans}</td>
                  </tr>
                  ))}  
                </tbody>
            </Table>
            </>
            }
          </div>
        </div>
      </Form>
      </>
    )
}

export default Patient;