import React, { useState, useEffect } from 'react';
import axios from 'axios';
import $ from 'jquery';

import {  getJson, Radio } from '@mobiscroll/react';
import { Form, Row, Col, Button, Table, Modal } from 'react-bootstrap';
import { confirm } from 'react-bootstrap-confirmation';

function Users() {
    const Idt_Usr = localStorage.getItem('token');

    const [users, setUsers] =  useState([]);
    const [id, setId] = useState(''); 
    const [add, setAdd] = useState(false);
    const [pwdShown, setPwdShown] = useState(false);

    const [name, setName] = useState('');
    const [pwd, setPwd] = useState('');
    const [level, setLevel] = useState('');
    const [praticien, setPraticien] = useState([]);
    const [defaultPraticien, setDefaultPraticien] = useState('');
    const [note, setNote] = useState('');
    const [ccam, setCcam] = useState('');
    const [showHoliday, setShowHoliday] = useState('');
    const [show, setShow] = useState(false);

    // CHECKBOX
    let [checkboxValues] = useState('');

    const [praticienData, setPraticienData] = useState([]);
    useEffect(() => {
        getJson('/api/ajax.php?ajax=getDefPra', (praticienData) => {
            setPraticienData(praticienData);
        });
    }, []);

    useEffect(() => {
        getusers();
    },[])

    async function getusers(){
        const res = await axios.get('/api/ajax.php?ajax=getUserData');
        setUsers(res.data) 
    } 

    async function adduser() {
        const checkedItems = $('input[type=checkbox]:checked').map(function() {
            return this.value; 
        }).get().join(", ");
        checkboxValues = checkedItems.split(', ');
        
        const obj = {name, pwd, level, defaultPraticien, note, ccam, showHoliday, checkboxValues};
        const res = await axios.post('/api/function.php?ajax=insertUser&Idt_Usr='+Idt_Usr, obj);
        getusers();
        clearState();
    }

    async function updateuser() {
        const checkedItems = $('input[type=checkbox]:checked').map(function() {
            return this.value; 
        }).get().join(", ");
        checkboxValues = checkedItems.split(', ');

        const obj = {id, name, pwd, level, defaultPraticien, note, ccam, showHoliday, checkboxValues};
        const res = await axios.put('/api/function.php?ajax=updateUser&Idt_Usr='+Idt_Usr, obj);
        getusers();
        clearState();
    }

    function addUpdate(e) {
        e.preventDefault(); 
        if(name !== '') {
            if(add) {
                if(pwd !== '') {
                    adduser();
                }
            } else {
                updateuser();
            }
        }
    }

    async function deleteuser(Idt_Usr_Del) { 
        const result = await confirm('Voulez-vous le supprimer ?');
        if(result === true) {
            const res = await axios.delete('/api/function.php?ajax=deleteUser&Idt_Usr='+Idt_Usr+'&Idt_Usr_Del='+Idt_Usr_Del);
            getusers();
        }
    } 

    async function getuser(Idt_Usr){
        const res = await axios.get('/api/function.php?ajax=getSingleUserData&Idt_Usr='+Idt_Usr);
        setId(res.data.id);
        setName(res.data.name);
        setLevel(res.data.level);
        setPraticien(res.data.praticien);
        setDefaultPraticien(res.data.defaultPraticien);
        setNote(res.data.note);
        setCcam(res.data.ccam);
        setShowHoliday(res.data.showHoliday);
        setShow(true);
        setAdd(false);
        setPwdShown(false);
    } 

    function clearState(){
        setId('');
        setName('');
        setPwd('');
        setLevel('');
        setPraticien('');
        setDefaultPraticien('');
        setNote('');
        setCcam('');
        setShowHoliday('');
        setShow(false);
        setAdd(false);
        setPwdShown(false);
    }

    // CHECKBOX
    $(function() {
        $(".checkPra input").each(function() {
            let val = $(this).val();
            if (praticien && praticien.includes(val)) {
                $(this).prop('checked', true);
            }
        });
    });

    return (
 
    <div className="py-4 px-3" id="userPage">

      <Button className="btn btn-sm btn-organizer mb-2" onClick={() => setAdd(true)} >Nouvel utilisateur</Button>

      <Modal show={add ? add : show} id="userModal">
        <Modal.Header closeButton>
          <Modal.Title>{add ? "Ajouter" : "Editer"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form noValidate>
                <Form.Group as={Row}>
                    <Form.Label className="mb-2" column sm={4}>Nom | Login</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            placeholder="Nom"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            isInvalid={name === ''}
                        />
                    </Col>

                    <Form.Label className="mb-2" column sm={4}>Mot de passe</Form.Label>
                    <Col sm={6}>
                        {add || (!add && pwdShown) ?
                            <Form.Control 
                                placeholder="Mot de passe"
                                value={pwd}
                                onChange={(e) => setPwd(e.target.value)}
                                isInvalid={pwd === ''}
                            />
                        : null }
                        
                        {!add ?
                            <button type="button" className="btn btn-sm btn-organizer" onClick={() => setPwdShown(pwdShown ? false : true)} > Changer le mot de passe </button>
                        : null }
                    </Col>

                    <Form.Label className="mb-2" column sm={4}>Niveau</Form.Label>
                    <Col sm={6}>
                        <Form.Select
                            value={level}
                            onChange={(e) => setLevel(e.target.value)}
                        >
                            <option value="5">Infirmière/Assistante</option>
                            <option value="10">Chirurgien</option>
                            <option value="13">Tec</option>
                            <option value="15">Administrateur</option>
                            <option value="20">Maitre du monde</option>
                        </Form.Select>
                    </Col>

                    <Form.Label className="mb-2" column sm={4}>Accès aux praticiens</Form.Label>
                    <Col sm={6}>
                        {praticienData.map(p => (
                            <Form.Check 
                                key={p.Idt_Pra}
                                className="checkPra"
                                type="checkbox"
                                label={p.Nme_Pra}
                                value={p.Idt_Pra}
                                name={p.Nme_Pra}
                            />
                        ))}
                    </Col>

                    <Form.Label className="mb-2" column sm={4}>Praticien par défaut</Form.Label>
                    <Col sm={6}>
                        <Form.Select
                            value={defaultPraticien}
                            onChange={(e) => setDefaultPraticien(e.target.value)}
                        >
                            <option value="0">Sélectionner un practicien</option>
                            {praticienData.map(p => (
                                <option key={p.Idt_Pra} value={p.Idt_Pra}>{p.Nme_Pra}</option>
                            ))}
                        </Form.Select>
                    </Col>

                    <Form.Label className="mb-2" column sm={4}>Accès aux notes</Form.Label>
                    <Col sm={6}>
                        <div className="mbsc-form-group radio_box user_radio">
                            <Radio name="noteCheck" value="1" checked={note === '1'} onChange={(e) => setNote(e.target.value)} > Oui </Radio>
                            <Radio name="noteCheck" value="0" checked={note === '0'} onChange={(e) => setNote(e.target.value)} > Non </Radio>
                        </div>
                    </Col>

                    <Form.Label className="mb-2" column sm={4}>Accès aux CCAM</Form.Label>
                    <Col sm={6}>
                        <div className="mbsc-form-group radio_box user_radio">
                            <Radio name="ccamCheck" value="1" checked={ccam === '1'} onChange={(e) => setCcam(e.target.value)} > Oui </Radio>
                            <Radio name="ccamCheck" value="0" checked={ccam === '0'} onChange={(e) => setCcam(e.target.value)} > Non </Radio>
                        </div>
                    </Col>

                    <Form.Label className="mb-2" column sm={4}>Affichage des vacances</Form.Label>
                    <Col sm={6}>
                        <div className="mbsc-form-group radio_box user_radio">
                            <Radio name="showHolidayCheck" value="1" checked={showHoliday === '1'} onChange={(e) => setShowHoliday(e.target.value)} > Oui </Radio>
                            <Radio name="showHolidayCheck" value="0" checked={showHoliday === '0'} onChange={(e) => setShowHoliday(e.target.value)} > Non </Radio>
                        </div>
                    </Col>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-sm btn-secondary" onClick={clearState} >Fermer</button>
            <button className="btn btn-sm btn-organizer" onClick={(e) => addUpdate(e)} >
                {add ? "Ajouter" : "Editer"}
            </button>
        </Modal.Footer>
      </Modal>

        <div className="col-sm-12 col-md-6">
            <Table bordered hover>
                <thead>
                <tr className="text-center">
                    <th>#</th>
                    <th>Nom | Login</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
            {users.map(user => (
                <tr key={user.id}>
                    <td className="text-center">{user.num}</td>
                    <td>{user.name}</td>
                    <td className="text-center">
                        <Button className="btn-organizer btn-sm mx-2" onClick={() => getuser(user.id)} >Editer</Button>
                        <Button className="btn-organizer btn-sm" onClick={() => deleteuser(user.id)} >Supprimer</Button>
                    </td>
                </tr>
            ))}  
                </tbody>
            </Table>
        </div>
    </div>

    );
}
export default Users;
   