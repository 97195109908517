import React, { useState } from 'react';
import Logo from '../assets/img/logo.png';
import { useHistory } from 'react-router-dom';  
import axios from 'axios';

function Login() {

    const history = useHistory();

    const[errorMsg, setErrorMsg] = useState('');
    const[successMsg, setSuccessMsg] = useState('');

    const[name, setName] = useState('');
    const[nameError, setNameError] = useState('');

    const[password, setPassword] = useState('');
    const[passwordError, setPasswordError] = useState('');

    const nameChange = (ev) => {
        setNameError('');
        setName(ev.target.value);
    }

    const passwordChange = (ev) => {
        setPasswordError('');
        setPassword(ev.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = { name, password };

        if(name==='') {
            setNameError('Le nom ne doit pas être vide');
        }
        if(password==='') {
            setPasswordError('Le mot de passe ne doit pas être vide');
        }
        
        axios.post(`/api/login.php?name=${name}&password=${password}`, user).then((response) => {
            if(response.data.length > 0) {
                localStorage.setItem('token', response.data[0].Idt_Usr);
                localStorage.setItem('level', response.data[0].Niv_Usr);
                localStorage.setItem('user', response.data[0].IdtDefPra_Usr);
                localStorage.setItem('hideEvent', response.data[0].IdtReePra_Usr);
                localStorage.setItem('expire', new Date().getTime())
                
                const pra_nme = response.data.map(x => x.text);
                const pra_val = response.data.map(x => x.value);
                localStorage.setItem('pra_nme', pra_nme);
                localStorage.setItem('pra_val', pra_val);

                setSuccessMsg("Connexion réussie");
                setErrorMsg('');

                window.location.reload();
                history.push("/agenda");
            } 
            else {
                if(name !== '' && password !== '') {
                    setErrorMsg("Nom et/ou mot de passe incorrect, merci de saisir à nouveau");
                    setSuccessMsg('');
                }
            }
        })
    }
    
    return (
            <form id="loginForm" onSubmit={handleSubmit}>
                <div className="text-center my-5 mx-2">
                  <img src={Logo} alt="Web Organizer Logo" height="45" />
                </div>
 
                {successMsg&&<div className="alertMessage alert alert-success">{successMsg}</div>}
                {errorMsg&&<div className="alertMessage alert alert-danger">{errorMsg}</div>}

                <div className="mb-4 fieldGroup row">
                    <label className="form-label col-4">Nom</label>
                    <input type="text" className="form-control col-8" placeholder="Votre nom" value={name} onChange={nameChange} />
                    {nameError&&<div className="form-text validationMessage">{nameError}</div>}
                </div>

                <div className="mb-4 fieldGroup row">
                    <label className="form-label col-4">Mot de passe</label>
                    <input type="password" className="form-control col-8" placeholder="Votre mot de passe" value={password} onChange={passwordChange} />
                    {passwordError&&<div className="form-text validationMessage">{passwordError}</div>}
                </div>

                <div className="mb-3 fieldGroup text-center">
                    <button type="submit" className="btn btn-sm btn-organizer">Valider</button>
                </div>
            </form>
    )
}

export default Login;