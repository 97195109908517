import React, { useState, useEffect } from 'react';
import { Form, FormControl, Row, Col, Table, Modal } from 'react-bootstrap';
import { getJson } from '@mobiscroll/react';
import axios from 'axios';
import $ from 'jquery';

function Ccam() {
  const Idt_Usr = localStorage.getItem('token');
  const level = localStorage.getItem('level');
  const [add, setAdd] = useState(false);
  const [ccamData, setCcamData] = useState([]);

  const [artCcam, setACcam] = useState([]);
  const [zonCcam, setZCcam] = useState([]);
  const [Art_Ccm, setArtCcam] = useState('');
  const [Zon_Ccm, setZonCcam] = useState('');

  const [Ope_Ccm, setOpeCcam1] = useState('');
  const [CodOpe_Ccm, setCodeOpeCcam1] = useState('');
  const [Val_Ccm, setValCcam1] = useState('');
  const [Op2_Ccm, setOpeCcam2] = useState('');
  const [CodOp2_Ccm, setCodeOpeCcam2] = useState('');
  const [Va2_Ccm, setValCcam2] = useState('');
  
  useEffect(() => {
      getJson('/api/ajax.php?ajax=getCcamData', (ccamData) => {
          setCcamData(ccamData);
      });
  },[]);

  useEffect(() => {
    getJson('/api/ajax.php?ajax=getArtCcam', (artCcam) => {
      setACcam(artCcam);
    });
  },[]);
  
  useEffect(() => {
    getJson(`/api/ajax.php?ajax=getZonCcam`, (zonCcam) => {
      setZCcam(zonCcam);
    });
  },[]); 

  const clearState = () => {
    setArtCcam('');
    setZonCcam('');
    setOpeCcam1('');
    setCodeOpeCcam1('');
    setValCcam1('');
    setOpeCcam2('');
    setCodeOpeCcam2('');
    setValCcam2('');
    setAdd(false);
  }

  const addCcam = () => {
    if(Art_Ccm != '' || Zon_Ccm != '') {
      axios.post(`/api/function.php?ajax=addCcam&Idt_Usr=${Idt_Usr}&Art_Ccm=${Art_Ccm}&Zon_Ccm=${Zon_Ccm}&Ope_Ccm=${Ope_Ccm}&Op2_Ccm=${Op2_Ccm}&CodOpe_Ccm=${CodOpe_Ccm}&CodOp2_Ccm=${CodOp2_Ccm}&Val_Ccm=${Val_Ccm}&Va2_Ccm=${Va2_Ccm}`).then((res) => {
        clearState();
        setTimeout(() => {
          window.location.reload(); 
        }, 1000);
      });
    } 
  }
  
  /************************************** AJAX **************************************/
  const showAll = (e) => {
    $('.tr_all').show();
    $(".btnSearch").removeClass("btn-organizer");
    $(e.target).addClass("btn-organizer");
  }

  const showArtCcam = (e) => {
    let artCcam = e.target.name;
    $('.tr_all').hide();
    $(".btnSearch").removeClass("btn-organizer");
    $(e.target).addClass("btn-organizer");
    $('.tr_'+artCcam).show();
  }
  
  const clearSearch = () => {
    $(".ccamSearch").val('');
    $('.tr_all').show();
  }

  const searchCcam = (e) => {
    let val = e.target.value;
    if (val!='') {
      $('.tr').hide();
      $('.Zon_Ccm, .Ope_Ccm, .Op2_Ccm').each(function(ev) {
        if ($(this).val()!='' && $(this).val()!=null) {
          if ($(this).val().toLowerCase().indexOf(val)>-1) {
            let Idt_Ccm = $(this).attr('Idt_Ccm');
            $('.tr_'+Idt_Ccm).show();
          }
        }
      });
    }else{
      $('.tr').show();
    }
  }
	
  const moveCcam = (e) => {
    let Idt_Ccm = $(e.target).attr("idt_ccm");
    let index = $(e.target).attr("index");
    axios.post(`/api/function.php?ajax=moveCcam&Idt_Usr=${Idt_Usr}&Idt_Ccm=${Idt_Ccm}&index=${index}`).then((res) => {
      if (index==-1) {
        $("#tr_"+Idt_Ccm).before($(e.target).closest('.tr_Ccam').next());
      }
      if (index==1) {
        $("#tr_"+Idt_Ccm).after($(e.target).closest('.tr_Ccam').prev());
      }
    });
  }

  const Art_CcmChange = (e) => {
    let Idt_Ccm = $(e.target).attr("idt_ccm");
    let Art_Ccm = e.target.value;
    axios.post(`/api/function.php?ajax=updateArt_Ccm&Idt_Usr=${Idt_Usr}&Art_Ccm=${Art_Ccm}&Idt_Ccm=${Idt_Ccm}`).then((res) => {
      $(e.target).val(Art_Ccm);
    });
  }

  const Zon_CcmChange = (e) => {
    let Zon_Ccm = e.target.value;
    let Idt_Ccm = $(e.target).attr("idt_ccm");
    axios.post(`/api/function.php?ajax=updateZon_Ccm&Idt_Usr=${Idt_Usr}&Zon_Ccm=${Zon_Ccm}&Idt_Ccm=${Idt_Ccm}`).then((res) => {
      $(e.target).val(Zon_Ccm);
    });
  }

  const checkChange = (e) => {
    let Idt_Ccm = $(e.target).attr("idt_ccm");
    let Col_Ccm = $(e.target).attr("field");
    let Val_Ccm;
    if (Idt_Ccm > 0) {
      calculate(Idt_Ccm);
    }
    $(e.target).prop('checked') ? Val_Ccm = 1 : Val_Ccm = 0;
    axios.post(`/api/function.php?ajax=updateCcam&Idt_Usr=${Idt_Usr}&Col_Ccm=${Col_Ccm}&Val_Ccm=${Val_Ccm}&Idt_Ccm=${Idt_Ccm}`).then((res) => {
      Val_Ccm == 1 ? $(e.target).prop('checked', true) : $(e.target).prop('checked', false)
    });
  }

  const ccamFieldOnChange = (e) => {
    let Idt_Ccm = $(e.target).attr("idt_ccm");
    let Col_Ccm = $(e.target).attr("field");
    let Val_Ccm = $(e.target).val();
    if (Idt_Ccm > 0) {
      calculate(Idt_Ccm);
    }
    axios.post(`/api/function.php?ajax=updateCcam&Idt_Usr=${Idt_Usr}&Col_Ccm=${Col_Ccm}&Val_Ccm=${Val_Ccm}&Idt_Ccm=${Idt_Ccm}`).then((res) => {
      $(e.target).val(Val_Ccm)
    });
  }
  
  const calculate = (Idt_Ccm) => {
    $('#Val_Ccm_'+Idt_Ccm).val($('#Val_Ccm_'+Idt_Ccm).val().replace(',','.'));
    $('#Va2_Ccm_'+Idt_Ccm).val($('#Va2_Ccm_'+Idt_Ccm).val().replace(',','.'));
    if ($('#Maj_Ccm_'+Idt_Ccm).prop('checked')){
      $('#priceMaj_Ccm_'+Idt_Ccm).val(Math.round(106.5*$('#Val_Ccm_'+Idt_Ccm).val())/100);
    } else {
      $('#priceMaj_Ccm_'+Idt_Ccm).val($('#Val_Ccm_'+Idt_Ccm).val());
    }
    if ($('#Ma2_Ccm_'+Idt_Ccm).prop('checked')){
      $('#priceMa2_Ccm_'+Idt_Ccm).val(Math.round(106.5*$('#Va2_Ccm_'+Idt_Ccm).val())/100);
    } else {
      $('#priceMa2_Ccm_'+Idt_Ccm).val($('#Va2_Ccm_'+Idt_Ccm).val());
    }

    if ($('#priceMaj_Ccm_'+Idt_Ccm).val()!='') {
      $('#tarif_'+Idt_Ccm).val(Math.round(100*$('#priceMaj_Ccm_'+Idt_Ccm).val()+100*$('#priceMa2_Ccm_'+Idt_Ccm).val()/2)/100);
      $('#s125_'+Idt_Ccm).val(Math.round(1.25*$('#tarif_'+Idt_Ccm).val()));
      $('#s150_'+Idt_Ccm).val(Math.round(1.50*$('#tarif_'+Idt_Ccm).val()));
      $('#s200_'+Idt_Ccm).val(Math.round(2*$('#tarif_'+Idt_Ccm).val()));
      $('#s250_'+Idt_Ccm).val(Math.round(2.5*$('#tarif_'+Idt_Ccm).val()));
      $('#s300_'+Idt_Ccm).val(Math.round(3*$('#tarif_'+Idt_Ccm).val()));
      $('#s350_'+Idt_Ccm).val(Math.round(3.5*$('#tarif_'+Idt_Ccm).val()));
      $('#s400_'+Idt_Ccm).val(Math.round(4*$('#tarif_'+Idt_Ccm).val()));
      $('#s500_'+Idt_Ccm).val(Math.round(5*$('#tarif_'+Idt_Ccm).val()));
    }
  }

  const initialize = () => {
    $('.Idt_Ccm').each(function() {
      calculate($(this).attr("idt_ccm"));
    });
    $('.editIdt_Ccm_0:not(.Art_Ccm)').hide();
    if(level < 15) {
      $('.ccamField').prop('readonly', true);
      $('.editField').hide();
      $('#tr_0').show();
    }
  }
  
  function showZon_Ccm(Idt_Ccm) {
    $('#Zon_Ccm_'+Idt_Ccm).after(`<input type="hidden" id="hiddenZon_Ccm_${Idt_Ccm}" class="Zon_Ccm editZon_Ccm cccamField Idt_Ccm_${Idt_Ccm}" idt_ccm=${Idt_Ccm} field="Zon_Ccm" value="">`);
    $('#Zon_Ccm_'+Idt_Ccm).after(`<input type="text" id="editZon_Ccm_${Idt_Ccm}" class="editZoneField Zon_Ccm editZon_Ccm cccamField Idt_Ccm_${Idt_Ccm}" idt_ccm=${Idt_Ccm} field="Zon_Ccm" value="">`);
    $('#Zon_Ccm_'+Idt_Ccm).hide();

    // Remove duplicates
    $('#editZon_Ccm_'+Idt_Ccm).each(function (i) {
      $('[id="' + this.id + '"]').slice(1).remove();
    });
	}

  let clicks = 0;
	function editZon_Ccm(e) {
    let Idt_Ccm = $(e.target).attr("idt_ccm");
    if (clicks%2 == 0){
      showZon_Ccm(Idt_Ccm);
      $('#editZon_Ccm_'+Idt_Ccm).val($('#Zon_Ccm_'+Idt_Ccm).val());
      $('#hiddenZon_Ccm_'+Idt_Ccm).val($('#Zon_Ccm_'+Idt_Ccm).val());
    } else {
      $('#Zon_Ccm_'+Idt_Ccm).show();
      $('.editZon_Ccm').remove();
    }
    ++clicks;
	}

	function newZon_Ccm(e) {
    let Idt_Ccm = $(e.target).attr("idt_ccm");
		showZon_Ccm(Idt_Ccm);
		$('#editZon_Ccm_'+Idt_Ccm).val('');
		$('#hiddenZon_Ccm_'+Idt_Ccm).val('');
	}

  $(document).off('change').on('change', '.editZoneField', function(e) {
    let Idt_Ccm = $(e.target).attr("idt_ccm");
		$('#Zon_Ccm_'+Idt_Ccm).show();
		$('#editZon_Ccm_'+Idt_Ccm).hide();
		if ($('#hiddenZon_Ccm_'+Idt_Ccm).val()=='') {
			$('.Zon_Ccm').each(function() {
        let Zon_Ccm = $('#editZon_Ccm_'+Idt_Ccm).val();
				if ($(this).attr('Art_Ccm')==$('#Zon_Ccm_'+Idt_Ccm).attr('Art_Ccm')) {
					$(this).append('<option value="'+Zon_Ccm+'">'+Zon_Ccm+'</option>');
				}
				$('#Zon_Ccm_'+Idt_Ccm+' option:last').prop('selected', true);
				$('#Zon_Ccm_'+Idt_Ccm).change();
        axios.post(`/api/function.php?ajax=addZon_Ccm&Idt_Usr=${Idt_Usr}&Zon_Ccm=${Zon_Ccm}&Idt_Ccm=${Idt_Ccm}`).then((res) => {
          initialize();
        });
			});
		} 
    else {
      let Zon_Ccm = $('#editZon_Ccm_'+Idt_Ccm).val();
      let oldZon_Ccm = $('#hiddenZon_Ccm_'+Idt_Ccm).val();
			$('.Zon_Ccm option').each(function(e) {
				if ($(this).val()==$('#hiddenZon_Ccm_'+Idt_Ccm).val()) {
					$(this).val(Zon_Ccm);
					$(this).html(Zon_Ccm);
				}
			});
      axios.post(`/api/function.php?ajax=editZon_Ccm&Idt_Usr=${Idt_Usr}&Zon_Ccm=${Zon_Ccm}&oldZon_Ccm=${oldZon_Ccm}`).then((res) => {
        initialize();
      });
		}
		$('.editZon_Ccm').remove();
	})

  $(function() {
    initialize();
  });

  return (
    <>
    {/* AJOUTER CCAM */}
    <Modal show={add} className="modalAddCcam">
        <Modal.Header>
          <Modal.Title>Ajouter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form noValidate>
                <Form.Group as={Row}>
                    <Form.Label className="mb-3" column sm={4}>Articulation</Form.Label>
                    <Col sm={6}>
                      <Form.Select
                        value={Art_Ccm}
                        onChange={(e) => setArtCcam(e.target.value)}
                     >
                        <option value="0">Sélectionner un articulation</option>
                        {artCcam.map((a, i) => ( 
                            <option key={i} value={a.Art_Ccm}> {a.Art_Ccm} </option>
                        ))} 
                      </Form.Select>
                    </Col>

                    <Form.Label className="mb-3" column sm={4}>Chirurgie</Form.Label>
                    <Col sm={6}>
                      <Form.Select
                        value={Zon_Ccm}
                        onChange={(e) => setZonCcam(e.target.value)}
                     >
                        <option value="0">Sélectionner un chirurgie</option>
                        {zonCcam.map((z, i) => ( 
                            <option key={i} value={z.Zon_Ccm}> {z.Zon_Ccm} </option>
                        ))} 
                      </Form.Select>
                    </Col>

                    <Form.Label className="mb-3" column sm={4}>Acte 1</Form.Label>
                    <Col sm={6}>
                        <input 
                          value={Ope_Ccm}
                          onChange={(e) => setOpeCcam1(e.target.value)}
                        />
                    </Col>

                    <Form.Label className="mb-3" column sm={4}>Code 1</Form.Label>
                    <Col sm={6}>
                        <input 
                          value={CodOpe_Ccm}
                          onChange={(e) => setCodeOpeCcam1(e.target.value)}
                        />
                    </Col>

                    <Form.Label className="mb-3" column sm={4}>Prix 1</Form.Label>
                    <Col sm={6}>
                        <input 
                          type="number"
                          placeholder='0.00'
                          value={Val_Ccm}
                          onChange={(e) => setValCcam1(e.target.value)}
                        />
                    </Col>

                    <Form.Label className="mb-3" column sm={4}>Acte 2</Form.Label>
                    <Col sm={6}>
                        <input 
                          value={Op2_Ccm}
                          onChange={(e) => setOpeCcam2(e.target.value)}
                        />
                    </Col>

                    <Form.Label className="mb-3" column sm={4}>Code 2</Form.Label>
                    <Col sm={6}>
                        <input
                          value={CodOp2_Ccm}
                          onChange={(e) => setCodeOpeCcam2(e.target.value)}
                        />
                    </Col>

                    <Form.Label className="mb-3" column sm={4}>Prix 2</Form.Label>
                    <Col sm={6}>
                        <input 
                          type="number"
                          placeholder='0.00'
                          value={Va2_Ccm}
                          onChange={(e) => setValCcam2(e.target.value)}
                        />
                    </Col>

                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn btn-sm btn-secondary" onClick={clearState}>Fermer</button>
            <button className="btn btn-sm btn-organizer" onClick={addCcam}>Ajouter</button>
        </Modal.Footer>
      </Modal>


    <Table bordered responsive>
      <thead></thead>
      <tbody>
      <tr className="text-center align-middle">
        <td>
          <button type="button" className="btn btn-sm btnSearch" onClick={showAll}>TOUS</button>
        </td>
        {artCcam.map((a, i) => ( 
          <td key={i}>
            <button type="button" className="btn btn-sm btnSearch" onClick={showArtCcam} name={a.Art_Ccm}>{a.Art_Ccm}</button>
          </td>
        ))} 
        <td>
          <div className='d-flex'>
            <FormControl className="d-flex ccamSearch w-75" type="text" aria-label="Search" placeholder="Rechercher ..." onChange={searchCcam} />
            <button type="button" onClick={clearSearch} className='btn btn-sm btn-organizer bi bi-x'></button>
          </div>
        </td>
        {level >= 15 ? 
          <td>
            <button type="button" className="btn btn-sm btn-organizer"onClick={() => setAdd(true)}>AJOUTER</button> 
          </td>
        : null}
      </tr>
      </tbody>
    </Table>
    <Table bordered hover responsive id="ccam-form">
      <thead>
        <tr className="text-center align-middle">
          {level >= 15 ?
            <th>&nbsp;Pos&nbsp;&nbsp;</th>
            : null
          }
          <th>Articulation</th>
          <th>Chirurgie</th>
          <th>Acte 1</th>
          <th>Code 1</th>
          <th>Prix 1</th>
          <th>J</th>
          <th>Acte 2</th>
          <th>Code 2</th>
          <th>Prix 2</th>
          <th>J</th>
          <th>Tarif</th>
          <th>S1,25</th>
          <th>S1,5</th>
          <th>S2</th>
          <th>S2,5</th>
          <th>S3</th>
          <th>S3,5</th>
          <th>S4</th>
          <th>S5</th>
        </tr>
      </thead>
      <tbody>
        {ccamData.map((data, index) => (
          <tr key={index} id={`tr_${data.Idt_Ccm}`} className={`align-middle tr_Ccam Idt_Ccm_${data.Idt_Ccm} tr tr_${data.Idt_Ccm} tr_${data.Art_Ccm} ${data.Idt_Ccm > 0 ? 'tr_all' : ''}`}>
            {level >= 15 ?
              <td>
                <button type="button" onClick={moveCcam} className={`btn btn-sm btn-position btn-icon px-0 bi bi-arrow-up-circle-fill Idt_Ccm_${data.Idt_Ccm}`} index="1" idt_ccm={data.Idt_Ccm}></button>&nbsp;
                <button type="button" onClick={moveCcam} className={`btn btn-sm btn-position btn-icon px-0 bi bi-arrow-down-circle-fill Idt_Ccm_${data.Idt_Ccm}`} index="-1" idt_ccm={data.Idt_Ccm}></button>
              </td>
              : null
            }
              <td>
                <input 
                  id={`Idt_Ccm_${data.Idt_Ccm}`} 
                  type="hidden" 
                  className={`Idt_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`} 
                  idt_ccm={data.Idt_Ccm} 
                  value={data.Idt_Ccm} 
                  field="Idt_Ccm" 
                />
                {level < 15 ? data.Art_Ccm
                : <select
                    id={`Art_Ccm_${data.Idt_Ccm}`} 
                    onChange={Art_CcmChange}
                    idt_ccm={data.Idt_Ccm}  
                    className={`Art_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`}
                    value={Art_Ccm ? Art_Ccm : data.Art_Ccm} 
                    field="Art_Ccm" 
                  >
                    <option value=""> -- </option> 
                    {artCcam.map((a, i) => ( 
                        <option key={i} value={a.Art_Ccm}> {a.Art_Ccm} </option>
                    ))} 
                  </select> 
                }
              </td>
              {level < 15 
                ? <td>{data.Zon_Ccm}</td>
                : <td>
                  <div className='flexDiv'>
                    <select
                        id={`Zon_Ccm_${data.Idt_Ccm}`} 
                        onChange={Zon_CcmChange}
                        idt_ccm={data.Idt_Ccm}  
                        className={`Zon_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`}
                        value={Zon_Ccm ? Zon_Ccm : data.Zon_Ccm} 
                        field="Zon_Ccm"
                      >
                        <option value=""> -- </option> 
                        {zonCcam.map((z, i) => ( 
                            <option key={i} value={z.Zon_Ccm}> {z.Zon_Ccm} </option>
                        ))} 
                      </select> 
                      <button type="button" onClick={editZon_Ccm} className={`editField btn btn-sm btn-icon px-0 bi bi-pencil-fill Idt_Ccm_${data.Idt_Ccm}`} idt_ccm={data.Idt_Ccm}></button>&nbsp;
                      <button type="button" onClick={newZon_Ccm} className={`editField btn btn-sm btn-icon px-0 bi bi-plus-circle-fill Idt_Ccm_${data.Idt_Ccm}`} idt_ccm={data.Idt_Ccm}></button>
                  </div>
                </td>
              }
              <td>
                <input
                  id={`Ope_Ccm_${data.Idt_Ccm}`} 
                  onChange={ccamFieldOnChange}
                  type="text"
                  idt_ccm={data.Idt_Ccm} 
                  className={`Ope_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`}
                  value={data.Ope_Ccm}
                  field="Ope_Ccm"
                />
              </td>
              <td>
                <input
                  id={`CodOpe_Ccm_${data.Idt_Ccm}`} 
                  onChange={ccamFieldOnChange}
                  type="text"
                  idt_ccm={data.Idt_Ccm} 
                  className={`CodOpe_Ccm ccamField Idt_Ccm_${data.Idt_Ccm} inputCode`}
                  style={{width: (data.CodOpe_Ccm.length + 1.5) + 'vh'}}
                  value={data.CodOpe_Ccm}
                  field="CodOpe_Ccm"
                />
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`Val_Ccm_${data.Idt_Ccm}`} 
                    onChange={ccamFieldOnChange}
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal Val_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`}
                    value={data.Val_Ccm}
                    field="Val_Ccm"
                  />
                  <span>€</span>
                </div>

              </td>
              <td>
                <div className='flexDiv'>
                  <Form.Check
                    id={`Maj_Ccm_${data.Idt_Ccm}`} 
                    onChange={checkChange}
                    type="checkbox"
                    idt_ccm={data.Idt_Ccm} 
                    className={`Maj_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`}
                    checked={data.Maj_Ccm == 1 ? 'checked' : ''}
                    value={data.Maj_Ccm}
                    field="Maj_Ccm"
                  />
                  <input
                    id={`priceMaj_Ccm_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <input
                  id={`Op2_Ccm_${data.Idt_Ccm}`} 
                  onChange={ccamFieldOnChange}
                  type="text"
                  idt_ccm={data.Idt_Ccm} 
                  className={`Op2_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`}
                  value={data.Op2_Ccm}
                  field="Op2_Ccm"
                />
              </td>
              <td>
                <input
                  id={`CodOp2_Ccm_${data.Idt_Ccm}`} 
                  onChange={ccamFieldOnChange}
                  type="text"
                  idt_ccm={data.Idt_Ccm} 
                  className={`CodOp2_Ccm ccamField Idt_Ccm_${data.Idt_Ccm} inputCode`}
                  style={{width: (data.CodOp2_Ccm.length + 1) + 'vh'}}
                  value={data.CodOp2_Ccm}
                  field="CodOp2_Ccm"
                />
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`Va2_Ccm_${data.Idt_Ccm}`} 
                    onChange={ccamFieldOnChange}
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal Va2_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`}
                    value={data.Va2_Ccm}
                    field="Va2_Ccm"
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <Form.Check
                    id={`Ma2_Ccm_${data.Idt_Ccm}`} 
                    onChange={checkChange}
                    type="checkbox"
                    idt_ccm={data.Idt_Ccm} 
                    className={`Ma2_Ccm ccamField Idt_Ccm_${data.Idt_Ccm}`}
                    checked={data.Ma2_Ccm == 1 ? 'checked' : ''}
                    value={data.Ma2_Ccm}
                    field="Ma2_Ccm"
                  />
                  <input
                    id={`priceMa2_Ccm_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`tarif_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 inputDecimal3 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`s125_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`s150_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`s200_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`s250_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`s300_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`s350_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`s400_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
              <td>
                <div className='flexDiv'>
                  <input
                    id={`s500_${data.Idt_Ccm}`} 
                    type="text"
                    idt_ccm={data.Idt_Ccm} 
                    className={`inputDecimal2 Idt_Ccm_${data.Idt_Ccm}`}
                    value=''
                    readOnly
                  />
                  <span>€</span>
                </div>
              </td>
          </tr>
        ))}  
      </tbody>
    </Table>
    </>
  )
}

export default Ccam;